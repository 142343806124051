<template>
  <div class="index pages" v-loading="pageLoading">
    <div class="">
      <div class="flex-row box-child">
        <div class="news-swipers">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item, index) in swiperArr" :key="index" >
              <img class="cursor-pointer" :src="item.sc_path" alt="/">
              <div class="hidemore sc_name">{{item.sc_name}}</div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="news-box flex-cell m-l-10">
          <div class="news-top flex-row txt-center">
            <div class="cursor-pointer txt-center tab" :class="tabIdx == index?'sel_tab':''" @click="doChangeTab(index)" v-for="(item, index) in tabArticles" :key="index">{{item.menuName}}</div>
            <div class="flex-cell"></div>
            <div class="txt-center more cursor-pointer" @click="$router.push({path: '/newsLists/'+tabArticles[tabIdx].menuCode+'/'+tabArticles[tabIdx].menuName})">
              更多
              <i class="iconfont icon19"></i>
            </div>
          </div>
          <div class="news-cont">
            <div class="flex-row txt-center news-cell cursor-pointer" v-for="(item, index) in tabArticles[tabIdx].articleContentList" :key="index" @click="$router.push({name: 'newsShow', params: {id: item.ac_id,menuCode: tabArticles[tabIdx].menuCode,menuName: tabArticles[tabIdx].menuName}})">
              <i class="iconfont icongengduo11"></i>
              <div class="flex-cell hidemore">{{item.ac_title}}</div>
              <div class="times">{{item.ac_pub_time.substring(0, 10)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modular flex-row">
        <div class="flex-cell txt-center cursor-pointer" @click="$router.push({ path: `/register` })">
          <img src="@/assets/icon/modular1.png" alt="">
        </div>
        <div class="flex-cell txt-center cursor-pointer" @click="isPopShow = true">
          <img src="@/assets/icon/modular2.png" alt="">
        </div>
        <div class="flex-cell txt-center cursor-pointer" @click="$router.push({name: 'search', params: {idx: 1, val: ''}})">
          <img src="@/assets/icon/modular3.png" alt="">
        </div>
        <div class="flex-cell txt-center cursor-pointer" @click="$router.push({ path: `/serviceType` })">
          <img src="@/assets/icon/modular4.png" alt="">
        </div>
      </div>
      <div class="flex-row box-child">
        <div class="flex-cell" style="min-width: 0">
          <div class="flex-warp">
            <div class="news-box flex-cell display-inline-block m-t-10" :class="index%2 == 1?'m-l-10':''" v-for="(item, index) in topArticles" :key="index">
              <div class="news-top news-top-s flex-row txt-center">
                <div class="txt-center tabs"><i class="iconfont iconwangyesheji"></i> {{item.menuName}}</div>
                <div class="flex-cell"></div>
                <div class="txt-center more cursor-pointer" @click="$router.push({path: '/newsLists/'+item.menuCode+'/'+item.menuName})">
                  更多
                  <i class="iconfont icon19"></i>
                </div>
              </div>
              <div class="news-cont">
                <div class="flex-row txt-center news-cell cursor-pointer" v-for="(items, indexs) in item.articleContentList" :key="indexs" @click="$router.push({name: 'newsShow', params: {id: items.ac_id,menuCode: item.menuCode,menuName: item.menuName}})">
                  <i class="iconfont icongengduo11"></i>
                  <div class="flex-cell hidemore">{{items.ac_title}}</div>
                  <div class="times">{{items.ac_pub_time.substring(0, 10)}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="advertisement m-t-10" @click="$router.push({name: 'newsShow', params: {id: 228,menuCode:3,menuName: '行业资讯'}})">
            <img class="cursor-pointer " src="@/assets/images/advertisement.png" alt="">
          </div>
        </div>
        <div class="flex-col m-t-10 quick m-l-10">
          <div class="news-box flex-cell">
              <div class="news-top news-top-s flex-row txt-center">
                <div class="txt-center tabs"><i class="iconfont iconwangyesheji"></i> 快捷入口</div>
                <div class="flex-cell"></div>
              </div>
              <div class="quick-cont">
                <img class="cursor-pointer" @click="$router.push({name: 'newsShow', params: {id: 234,menuCode: 4,menuName: '标准规范'}})"  src="@/assets/images/quick1.png" alt="">
                <img class="cursor-pointer" @click="$router.push({name: 'newsShow', params: {id: 235,menuCode: 4,menuName: '标准规范'}})" src="@/assets/images/quick2.png" alt="">
                <img class="cursor-pointer" @click="$router.push({path: '/newsLists/13/红榜'})" src="@/assets/images/news5.png" alt="">
                <img class="cursor-pointer" @click="$router.push({path: '/newsLists/14/黑榜'})" src="@/assets/images/news4.png" alt="">
              </div>
            </div>
        </div>
      </div>
      <div class="flex-row m-t-10 box-child">
        <div class="flex-cell">
          <div class="news-box flex-cell">
              <div class="news-top news-top-s flex-row txt-center">
                <div class="txt-center tabs"><i class="iconfont iconwangyesheji"></i> 家政服务人员信用评价等级排名</div>
                <div class="flex-cell"></div>
              </div>
              <div class="level flex-row">
                <div class="start-level">
                  <div class="level-child cursor-pointer" :class="index == selLevel?'level_yes':'level_no'" v-for="(item, index) in servantCreditRank" :key="index" @click="doSetLevel(index)"><i class="iconfont iconjiangbei-"></i> {{item.dictLabel}}</div>
                </div>
                <div class="flex-cell level-cont">
                  <div class="flex-row level-list border-b">
                    <div class="level-name">姓名</div>
                    <div class="flex-cell">公司名称</div>
                  </div>
                  <div class="flex-row level-list" :class="index<3?'border-b':''" v-for="(item, index) in servantCreditRank[selLevel].detail" :key="index">
                    <div class="level-name">{{item.crEmpName}}</div>
                    <div class="flex-cell">{{item.crComName}}</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="flex-cell m-l-10">
          <div class="news-box flex-cell">
              <div class="news-top news-top-s flex-row txt-center">
                <div class="txt-center tabs"><i class="iconfont iconwangyesheji"></i> 家政服务人员信用等级评价标准</div>
                <div class="flex-cell"></div>
              </div>
              <div class="evaluate">
                <div class="flex-row">
                  <el-tooltip content="本评价项目根据甘肃省家政服务行业信用评价等级标准v202010版本制定" placement="right-start" effect="light">
                    <div class="evaluate-left"><i class="iconfont iconxiangmu"></i> 评价项目</div>
                  </el-tooltip>
                  <div class="flex-cell">
                    <el-tooltip :content="item.tips" placement="right" effect="light" v-for="(item, index) in evaluateProject" :key="index">
                      <div class="evaluate-project hidemore" ><i class="iconfont icongouxuan"></i> {{item.label}}</div>
                    </el-tooltip>
                  </div>
                </div>
                <div class="flex-row">
                  <el-tooltip content="以上项目每符合一项积1分，满分10分，按照对应得分划分等级" placement="right-start" effect="light">
                    <div class="evaluate-left"><i class="iconfont icondefen"></i> 评价等级</div>
                  </el-tooltip>
                  <div class="flex-cell">
                    <div class="evaluate-level" v-for="(item, index) in evaluateLevel" :key="index"><i class="iconfont" :class="index == 0?'icondengji-A font-weight':index == 1?'icondengji-A':index == 2?'icondengji-B':index == 3?'icondengji-C':'icondengji-D'"></i> {{item}}</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="header_other flex-col txt-center m-t-10">
        <div class="txt-center title">甘肃省家政服务业信用信息平台数据</div>
        <div class="flex-row flex-cell other-nav">
          <div class="flex-col txt-center cursor-pointer other-nav1 flex-cell">
            <div class="num txt-center">{{statisticDash.companyCount || 0}}</div>
            <i class="iconfont icongaiicon-"></i>
            <div class="txt m-t-15">家政公司</div>
          </div>
          <div class="flex-col txt-center cursor-pointer other-nav2 flex-cell">
            <div class="num txt-center">{{statisticDash.servantCount || 0}}</div>
            <i class="iconfont iconyonghuqun"></i>
            <div class="txt m-t-15">服务人员</div>
          </div>
          <div class="flex-col txt-center cursor-pointer other-nav3 flex-cell">
            <div class="num txt-center">{{statisticDash.redListCount || 0}}</div>
            <i class="iconfont iconicon_xinyong_xianxing_jijin- font-weight"></i>
            <div class="txt m-t-15">守信</div>
          </div>
          <div class="flex-col txt-center cursor-pointer other-nav4 flex-cell">
            <div class="num txt-center">{{statisticDash.blackListCount || 0}}</div>
            <i class="iconfont iconicon_xinyong_xianxing_jijin-1 font-weight"></i>
            <div class="txt m-t-15">一般守信</div>
          </div>
          <div class="flex-col txt-center cursor-pointer other-nav5 flex-cell">
            <div class="num txt-center">{{statisticDash.blackListCount || 0}}</div>
            <i class="iconfont iconyidaoheimingdan"></i>
            <div class="txt m-t-15">失信</div>
          </div>
        </div>
      </div>
      <div class="flex-row m-t-10 box-child">
        <div class="news-box flex-cell links">
          <div class="news-top news-top-s flex-row txt-center">
            <div class="txt-center tabs"><i class="iconfont iconwangyesheji"></i> 友情链接</div>
            <div class="flex-cell"></div>
          </div>
          <div class="links-cont">
            <div class="links-child cursor-pointer" v-for="(item, index) in friendlyLink" :key="index" @click="doOpenLink(item)">
              <div class="imgs">
                <img :src="item.img" alt="">
              </div>
              <div class="txt-center txt">{{item.name}}</div>
              <div class="links-other" v-if="index == 8">
                <div class="other-child cursor-pointer" v-for="(items, indexs) in item.children" :key="indexs" @click="doOpenLink(items)"><i class="iconfont iconjingwuicon_svg-"></i> {{items.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="isPopShow" width="500px">
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-position="top"
      >
        <el-form-item label="请输入验证码进行登录" prop="captcha">
          <div class="flex-row txt-center">
            <el-input
              class="flex-cell"
              v-model="formData.captcha"
            ></el-input>
            <div class="flex-cell" @click="refreshCode()">
              <div class="codeImg">
                <s-identify :identifyCode="identifyCode"></s-identify>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('formData')">关 闭</el-button>
        <el-button type="primary" @click="doSubmit('formData')"
          >提 交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { requestGet } from "@/api/home/index";
import sIdentify from "@/components/sIdentify.vue";
export default {
  name: "Home",
  components: { sIdentify },
  props: {},
  data() {
    var checkCode = (rule, value, callback) => {
      if (value != this.identifyCode) {
        callback(new Error("验证码输入有误，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      pageLoading: false,
      tabIdx: 0, // 新闻类型索引
      selLevel: 0,  //公司登记索引
      evaluateProject: [
        {label:'真实准确的个人及从业信息',tips: '提交到家政平台的个人基本、单位、从业、培训等信息是否真实是否准确'},
        {label:'持有上岗所需的合格证件',tips: '从事家政服务行业需要的证件，比如健康证、特殊技能证书等'},
        {label:'购买家政服务行业类保险且有效',tips: '购买家政行业相关的从业保险且保险处于生效期间'},
        {label:'获得过市级以上家政服务赛的名次',tips: '参与省市区举办的家政活动比赛前五名，参赛人员需超过20人以上'},
        {label:'获得过市级以上信用平台表彰',tips: '市级及以上信用平台点名表扬或者颁发表彰证书'},
        {label:'没有过犯罪记录',tips: '犯罪前科，包含任一国家法律规定的犯罪情况'},
        {label:'没有过家政服务行业民事判决',tips: '是否有过家政服务行业相关的民事纠纷或者判决'},
        {label:'没有过严重客户投诉',tips: '消费者通过消费者热线、者家政信用平台或者其他信用平台投诉'},
        {label:'没有过失信记录',tips: '信用平台列入失信名单'},
        {label:'没有过消费者差评',tips: '消费者通过消费者热线、者家政信用平台或者其他信用平台给予差评'}],
      evaluateLevel: [
        '诚信模范-9项及以上',
        '诚信-8项 ',
        '较诚信-7项',
        '诚信警示-6项',
        '不诚信-5项及以下'
      ],
      servantCreditRank: [{detail: []}],
      friendlyLink: [],
      statisticDash: {},
      tabArticles: [{articleContentList: []}],
      topArticles: [],
      swiperArr: [],
      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
      ],
      formData:{
        captcha: ''
      },
      rules: {
        captcha: [
          { required: true, message: "请输入验证码！", trigger: "blur" },
          { validator: checkCode, trigger: "blur" },
        ],
      },
      isPopShow: false
    };
  },
  mounted() {
    l_loadData(this)
    this.refreshCode();
  },
  methods: {
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      console.log("当前验证码:", this.identifyCode);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      console.log("data, len:", data, len);
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    // 选择新闻类型
    doChangeTab(idx){
      this.tabIdx = idx
    },
    // 选择等级
    doSetLevel(idx){
      this.selLevel = idx
    },
    // 友情链接
    doOpenLink(item){
      if(item.url && item.url != ''){
        window.open(item.url)
      }
    },
    // 提交
    doSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          window.open('https://www.cxpt-gssjx.cn:8088/login?u=308')
          this.resetForm(formName)
        }else{
          this.refreshCode()
        }
      });
    },
    // 取消重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.isPopShow = false;
    },
  }
};
  // 加载数据
function l_loadData(pg){
  pg.pageLoading = true;
  requestGet("api/index", {})
    .then((res) => {
      pg.servantCreditRank = res.data.servantCreditRank || []
      pg.friendlyLink = res.data.friendlyLink || []
      pg.statisticDash = res.data.statisticDash || {}
      pg.tabArticles = res.data.tabArticles || []
      pg.topArticles = res.data.topArticles || []
      pg.swiperArr = res.data.sysAdConf || []
      pg.pageLoading = false;
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
}
</script>
<style lang="scss" scoped>
.box-child{
  min-width:0;
  .display-inline-block{
    width: calc(50% - 5px);
    vertical-align: top;
    min-height: 220px;
  }
  .news-swipers{
    width: 700px;
    height: 350px;
    position: relative;
    ::v-deep .el-carousel{
      width: 100%;
      height: 350px;
      .el-carousel__item{
        width: 100%;
        height: 350px;
        z-index: 0;
      }
    }
    img{
      width: 100%;
      height: 350px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .sc_name{
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.2);
      color: #fff;
      font-size: 14px;
      padding: 10px 20px;
      width: calc(100% - 40px);
    }
    ::v-deep .el-carousel__indicators{
      right: 20px!important;
      bottom: 2px;
      transform: translate(0)!important;
      .el-carousel__indicator{
        width: 10px!important;
        height: 10px!important;
        border-radius: 10px;
        .el-carousel__button{
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
    }
    ::v-deep .el-carousel__indicators--horizontal{
      left: auto!important;
    }
  }
  .news-box{
    min-width:0;
    background: #fff;
    .news-top{
      font-size: 14px;
      border-bottom: 1px solid #db3e3e;
      height: 35px;
      .tab{
        background: #fff;
        height: 100%;
        padding: 0 14px;
      }
      .sel_tab{
        background: #db3e3e;
        color: #fff;
        
      }
      .tabs{
        height: 36px;
        border-bottom: 6px solid #ea3e3e;
        font-size: 16px;
        .iconfont{
          color: #db3e3e;
          margin: 0 10px;
        }
      }
      .more{
        font-size: 12px;
        margin-right: 10px;
        .iconfont{
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .more:hover{
        color: #db3e3e;
      }
    }
    
    .news-top-s{
      height: 42px!important;
    }
    .news-cont{
      .news-cell{
        border-bottom: 1px dotted #ddd;
        font-size: 14px;
        padding: 7px 0;
        text-indent: 10px;
        .iconfont{
          font-size: 10px;
        }
        .times{
          font-size: 12px;
          color: #999;
          margin-right: 10px;
        }
      }
      .news-cell:hover{
        background: #e6f7ff;
      }
    }
  }
  .advertisement{
      width: calc(100% - 20px);
      height: 100px;
      padding: 10px;
      background: #fff;
      img{
        width: 100%;
        height: 100%;
      }
    }
  .quick{
    width: 300px;
    .quick-cont{
      width: 100%;
      padding-top: 5px;
      img{
        width: 94%;
        height: 120px;
        margin: 4px 3%;
        opacity: 1;
      }
      img:hover{
        opacity: .8;
      }
    }
  }
  .level{
    font-size: 14px;
    padding: 10px 0;
    .start-level{
      width: 100px;
      .level-child{
        height: 31px;
        line-height: 31px;
        padding-left: 10px;
      }
      .level_yes{
        color: #fff;
        background: #ec7259;
      }
      .level_no{
        .iconfont{
          color: #db3e3e;
        }
      }
    }
    .level-cont{
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      margin: 0 10px;
      padding: 0 10px;
      .level-list{
        height: 30px;
        line-height: 30px;
        .level-name{
          width: 85px;
        }
      }
      .border-b{
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
  .evaluate{
    font-size: 14px;
    padding: 10px;
    .evaluate-left{
      padding-left: 10px;
      margin-bottom: 3px;
      .iconfont{
        color: #db3e3e;
      }
    }
    .evaluate-project{
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 20px);
      padding-left: 20px;
      margin-bottom: 3px;
      .iconfont{
        color: #db3e3e;
      }
    }
    .evaluate-level{
      display: inline-block;
      padding-left: 20px;
      margin-bottom: 4px;
    }
  }
  .links{
    .links-cont{
      .links-child{
        display: inline-block;
        width: 160px;
        margin: 20px;
        font-size: 14px;
        position: relative;
        .imgs{
          border: 1px solid #ddd;
          font-size: 0;
          img{
            width: 150px;
            margin: 4px;
            height: 60px;
          }
        }
        .txt{
          margin-top: 10px;
        }
        .links-other{
          display: none;
          position: absolute;
          left: 152px;
          top: -40px;
          width: 500px;
          box-shadow: 0 0 10px #ccc;
          z-index: 100000;
          text-align: left;
          border: 1px solid #ddd;
          padding: 10px 20px;
          background: #fff;
          .other-child{
            display: inline-block;
            text-decoration: underline;
            color: #606060;
            line-height: 26px;
            font-size: 12px;
            width: 125px;
            .iconfont{
              color: #db3e3e;
            }
          }
          .other-child:hover{
            color: #db3e3e!important;
          }
        }
      }
      .links-child:hover{
        .links-other{
          display: block;
        }
      }
    }
  }
}

.modular{
  margin: 20px 0;
  img{
    width: 120px;
  }
  :hover {
    opacity: 0.6;
  }
}

.header_other{
  background-image: url('../../assets/images/bg.png');
  background-size: 100%;
  width: 100%;
  height: 320px;
  background-repeat: no-repeat;
  color: #fff;
  .title{
    font-size: 35px;
    padding-top: 40px;
  }
  .other-nav{
    width: 1000px;
    font-size: 14px;
    .iconfont{
      font-size: 40px;
      margin-top: 15px;
    }
    .num{
      font-size: 26px;
      transition:all 0.3s ease;
      height: 50px;
    }
    .cursor-pointer{
      min-width: 100px;
    }
  }
  .other-nav1:hover{
    .num{
      font-size: 35px;
    }
  }
  .other-nav2:hover{
    .num{
      font-size: 35px;
    }
  }
  .other-nav3:hover{
    .num{
      font-size: 35px;
    }
  }
  .other-nav4:hover{
    .num{
      font-size: 35px;
    }
  }
  .other-nav5:hover{
    .num{
      font-size: 35px;
    }
  }
}
.codeImg {
    width: 200px;
    height: 40px;
    margin-left: 20px;
  }
</style>
